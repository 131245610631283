import type { LatLng } from '@pepita-canary/leaflet';

import { countryCode, language, locale } from './constants';

import { isDev, isProd } from 'src/utils/environment';
import { getPlaceSelectionMinZoom } from 'src/utils/mapConfig';

export const mapConfig = {
  attribution: 'Indomio.gr',
  language: locale,
  tilesUrlTemplate:
    'https://maps.im-cdn.it/tiles/immobiliare/{z}/{x}/{y}.png?version=2',
  markerTilesUrl: isProd
    ? 'https://tiler.indomio.gr/custom_tiles/points/{z}/{x}/{y}.png'
    : 'https://tiler-staging.indomio.gr/custom_tiles/points/{z}/{x}/{y}.png',
  polygonServiceHost: isProd ? 'https://s1.indomio.gr' : '',
  polygonsServiceVersion: '10',
  polygonNamingServiceHost: '',
  polygonNamingServiceVersion: '1',
  polygonsServiceLanguage: language,
  poiCdnVersion: '1',
  center: { lat: 38.7416039, lng: 22.5254579 } as LatLng,
  zoom: 7,
  minZoom: 7,
  placeSelectionMinZoom: getPlaceSelectionMinZoom(5, 6, 7),
  placeSelectionMaxZoom: 16,
  drawOnMapMinZoom: 7,
  drawOnMapMaxZoom: 17,
  routeSearch: false,
  geocodingOptions: {
    language: locale,
    host: isDev
      ? 'https://geocoder-api.rete.loc/v1'
      : 'https://geocoder.ekbl.net/v1',
    country: countryCode,
    key: isDev ? 'dev' : 'caronte_gr',
  },
};
